import React, { useEffect, useState } from "react";
import IconAddRound from "../../../assets/img/icon-addround.svg";
import MinusPNG from "../../../assets/img/minus.png";
import iconMute from "../../../assets/img/icon-mute.png";
import iconUnMute from "../../../assets/img/icon-unmute.png";
import SelectEvent from "./streamType.js";
import { FileUpload } from "../../../redux/actions/user";
import { Validation } from "../../../helpers/validations";
import {
  CreateEventActor,
  GetTimeZones,
  CheckCorporateEmail,
  GetTaxValue,
  CheckCreateEvent,
  CheckCompany,
} from "../../../redux/actions/events";
import Progress from "../../../components/progress/index";
import { GetCategoryList } from "../../../redux/actions/user";
import { Notify } from "../../../helpers/notifications";
import VideoThumbnail from "react-video-thumbnail";
import { connect } from "react-redux";
import { GetMyEvents } from "../../../redux/actions/events";
import Logo from "react-loader-spinner";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getCalculatedSellingPrice,
  getCalculatedReversePrice,
} from "../../../helpers/constants";
import Store from "../../../redux/store/store";
import SelectQuality from "./streamQuality";

function CreateEvent({ setSelectedTab, GetMyEvents }) {

  const [audience, setAudience] = useState("");
  const [eventCategory, setEventCategory] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [eventTitle, setEventTitle] = useState("");
  const [ticketType, setTicketType] = useState("");
  const [checkedLimited, setCheckedLimited] = useState(false);
  const [checkedUnLimited, setCheckedUnLimited] = useState(false);
  const [ticketPrice, setTicketPrice] = useState("");
  const [noOfTickets, setNoOfTickets] = useState("");
  const [noOfEBTickets, setNoOfEBTickets] = useState("");
  const [discount, setDiscount] = useState("");
  const [earningsTicketPrice, setEarningsTicketPrice] = useState("");
  const [earningsTicketSold, setEarningsTicketSold] = useState("");
  const [earningsEst, setEarningsEst] = useState("");
  const [timezone, setTimeZone] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [unixDate, setUnixDate] = useState("");
  const [eventFile, setEventFile] = useState("");
  const [eventFileType, setEventFileType] = useState("");
  const [eventFileName, setEventFileName] = useState("");
  const [eventFilePreview, setEventFilePreview] = useState("");
  const [videoThumbnail, setVideoThumbnail] = useState("");
  const [description, setDescription] = useState("");
  const [eventPreviewClicked, setEventPreviewClicked] = useState(false);
  const [corporateEmail, setCorporateEmail] = useState([
    {
      email: "",
      price: "",
      sellingPrice: "",
    },
  ]);
  const [error, setError] = useState({});
  const [corporateEmailError, setCorporateEmailError] = useState();
  const [calcTicPrice, setCalcTicPrice] = useState();
  const [calcNoOfTickets, setCalcNoOfTickets] = useState();
  const [calcEstimate, setCalcEstimate] = useState();
  const [corporateChecked, setCorporateChecked] = useState("");
  const [videoMute, setVideoMute] = useState(true);
  const [generateThumb, setGenerateThumb] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [tzList, setTZList] = useState([]);
  const [disableCreate, setDisableCreate] = useState(false);
  const [duration, setDuration] = useState("");
  const [ticketCom, setTicketCom] = useState("");
  const [ticketSF, setTicketSF] = useState("");
  const [ticketVat, setTicketVat] = useState("");
  const [normalSellingPrice, setNormalSellingPrice] = useState("");
  const [pricePerTicket, setPricePerTicket] = useState("");
  const [EBSellingPrice, setEBSellingPrice] = useState("");
  const [EByesno, setEByesno] = useState("no");
  const [checkActorStatus, setCheckActorStatus] = useState(false);
  const [addSpecificPrice, setAddSpecificPrice] = useState("");
  const [eventId, setEventId] = useState("");
  const [stepper, setStepper] = useState(1);
  const [streamType, setStreamType] = useState("easy");
  const [showCreateForm, setShowCreateForm] = useState(false);

  const [showStreamQuality, setShowStreamQuality] = useState(false);

  const streamPlatform = streamType == 'easy' ? 'easyStream' : 'rtmp';
  const streamPlatformQuality = showStreamQuality == false ? null : showStreamQuality;
  const platformType = streamType == "easy" ? 'easyStream' : (streamType == "rtmp" && streamPlatformQuality == '4k' ? 'rtmp_4k' : 'rtmp_1080p')

  useEffect(() => {
    document.title = "LiveStage | Actor - Create Event";
  }, []);

  // Get Category List and TZ
  useEffect(() => {
    GetCategoryList()
      .then((response) => {
        setCategoryList([...response.data.data]);
      })
      .catch((error) => {
        console.log(error.response);
      });

    GetTimeZones()
      .then((response) => {
        setTZList([...response.data.data]);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);

  // Get Service Tax Variables
  useEffect(() => {
    GetTaxValue()
      .then((response) => {
        const taxData = response.data.data;
        for (let i = 0; i < taxData.length; i++) {
          if (taxData[i].name == platformType) {
            setTicketCom(taxData[i].commissionFee);
            setTicketSF(taxData[i].serviceFee);
            setTicketVat(taxData[i].vatFee);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [platformType]);

  useEffect(() => {
    if (noOfEBTickets && discount) {
      let EBSell = parseFloat(
        normalSellingPrice - (normalSellingPrice * discount) / 100
      ).toFixed(2);
      if (ticketPrice) {
        if (EBSell < 5) {
          setError({
            ...error,
            EBSellingPrice: "Selling Price cannot be less than 5 Euros",
          });
        } else {
          setError({ ...error, EBSellingPrice: "" });
        }
      }
      setEBSellingPrice(ticketPrice ? EBSell : "");
      setPricePerTicket(
        getCalculatedReversePrice(
          ticketCom,
          ticketVat,
          ticketSF,
          parseFloat(EBSell)
        )
      );
    } else {
      setPricePerTicket("");
      setEBSellingPrice("");
    }
  }, [discount, noOfEBTickets, ticketPrice]);

  // Add New Corporate Email
  const handleAddCorporateEmail = async () => {
    setCorporateEmailError("");
    if (
      corporateEmail[0].price === "" ||
      corporateEmail[0].price === undefined ||
      corporateEmail[0].email === "" ||
      corporateEmail[0].email === undefined
    ) {
      setCorporateEmailError("Please fill both details");
    } else {
      if (!Validation.email(corporateEmail[0].email)) {
        setCorporateEmailError("Please enter the email in a valid format");
      } else if (!Validation.emailLength(corporateEmail[0].email)) {
        setCorporateEmailError("Max 100 characters allowed");
      } else if (similarEmailCheck()) {
        setCorporateEmailError("Cannot use same email");
      } else if (parseFloat(corporateEmail[0].price) < 74.99) {
        setCorporateEmailError("Selling Price cannot be less than 100 Euros");
      } else {
        try {
          const response = await CheckCorporateEmail({
            email: corporateEmail[0].email,
          });

          if (response && response.status === 200) {
            setCorporateEmail([
              {
                email: "",
                price: "",
                sellingPrice: "",
              },
              ...corporateEmail,
            ]);
          }
        } catch (error) {
          setCorporateEmailError(error.response.data.message);
          Notify("danger", `Error: ${error.response.data.message}`);
        }
      }
    }
  };

  const similarEmailCheck = () => {
    let isSame = false;
    for (let i = 1; i < corporateEmail.length; i++) {
      isSame =
        corporateEmail[0].email.toLowerCase() ==
        corporateEmail[i].email.toLowerCase();
      if (isSame) break;
    }
    return isSame;
  };

  // Remove Corporate Email
  const handleRemoveCorporateEmail = (index) => {
    let newArray = corporateEmail.filter((item, i) => index !== i);
    setCorporateEmail(newArray);
  };

  // Handle Email and Price Text
  const handleCorporateEmailText = async (text, index, type) => {
    let newArray = corporateEmail.map((item, i) =>
      index == i
        ? type === "email"
          ? {
            ...item,
            email: text,
          }
          : type === "price"
            ? {
              ...item,
              price: handleCorporatePriceChange(text),
              sellingPrice: text
                ? getCalculatedSellingPrice(
                  ticketCom,
                  ticketVat,
                  ticketSF,
                  handleCorporatePriceChange(text)
                )
                : "",
            }
            : {
              ...item,
              sellingPrice: handleCorporatePriceChange(text),
              price: text
                ? getCalculatedReversePrice(
                  ticketCom,
                  ticketVat,
                  ticketSF,
                  handleCorporatePriceChange(text)
                )
                : "",
            }
        : item
    );

    setCorporateEmail(newArray);
  };

  useEffect(() => {
    var total = 0;
    for (let i = 0; i < corporateEmail.length; i++) {
      if (corporateEmail[i].price) {
        total = total + parseFloat(corporateEmail[i].price);
      }
    }
    setAddSpecificPrice(total);
  }, [corporateEmail]);

  // Handling ticket pricing to 2 decimal places
  const handlePriceChange = (value, field) => {
    if (field === "price") {
      if (value.split(".")[1] || value.split(".")[1] == "") {
        let newValue =
          value.split(".")[0] +
          "." +
          value
            .slice(value.indexOf("."), value.length)
            .replace(/[^0-9]/g, "")
            .slice(0, 2);

        let sellPrice = getCalculatedSellingPrice(
          ticketCom,
          ticketVat,
          ticketSF,
          parseFloat(newValue)
        );
        setTicketPrice(newValue);
        setNormalSellingPrice(sellPrice !== "NaN" ? sellPrice : "");
        setEarningsTicketPrice(newValue);
        setCalcTicPrice(newValue);
      } else {
        let sellPrice = getCalculatedSellingPrice(
          ticketCom,
          ticketVat,
          ticketSF,
          parseFloat(value)
        );
        setTicketPrice(value);
        setNormalSellingPrice(sellPrice !== "NaN" ? sellPrice : "");
        setEarningsTicketPrice(value);
        setCalcTicPrice(value);
      }
    } else {
      if (value.split(".")[1] || value.split(".")[1] == "") {
        let newValue =
          value.split(".")[0] +
          "." +
          value
            .slice(value.indexOf("."), value.length)
            .replace(/[^0-9]/g, "")
            .slice(0, 2);

        let reversePrice = getCalculatedReversePrice(
          ticketCom,
          ticketVat,
          ticketSF,
          parseFloat(newValue)
        );

        setNormalSellingPrice(newValue);
        setTicketPrice(
          reversePrice < 0 || reversePrice === "NaN" ? "" : reversePrice
        );
        setEarningsTicketPrice(
          reversePrice < 0 || reversePrice === "NaN" ? "" : reversePrice
        );
        setCalcTicPrice(
          reversePrice < 0 || reversePrice === "NaN" ? "" : reversePrice
        );
      } else {
        let reversePrice = getCalculatedReversePrice(
          ticketCom,
          ticketVat,
          ticketSF,
          parseFloat(value)
        );
        setNormalSellingPrice(value);
        setTicketPrice(
          reversePrice < 0 || reversePrice === "NaN" ? "" : reversePrice
        );
        setEarningsTicketPrice(
          reversePrice < 0 || reversePrice === "NaN" ? "" : reversePrice
        );
        setCalcTicPrice(
          reversePrice < 0 || reversePrice === "NaN" ? "" : reversePrice
        );
      }
    }
  };

  const handleCorporatePriceChange = (value) => {
    if (value.split(".")[1] || value.split(".")[1] == "") {
      return (
        value.split(".")[0] +
        "." +
        value
          .slice(value.indexOf("."), value.length)
          .replace(/[^0-9]/g, "")
          .slice(0, 2)
      );
    } else {
      return value;
    }
  };

  // File Handling and upload
  const handleImage = async (file) => {
    if (file) {
      setGenerateThumb(false);
      setUploadStatus(true);
      setEventFileType(file.type);

      let reader = new FileReader();
      reader.onload = async () => {
        let preview = URL.createObjectURL(file);

        if (file.type.split("/")[0] === "image") {
          if (file.size > 51857600) {
            setError({
              ...error,
              eventFile: "Event Image should be less than 50 MB",
            });
            setUploadStatus(false);
            return;
          }

          let img = new Image();
          img.src = preview;
          img.onload = async () => {
            if (img.width / img.height < 1) {
              setError({
                ...error,
                eventFile:
                  "Event Image should have an Aspect Ratio of 1:1, 4:3, 16:9, 16:10",
              });
              setUploadStatus(false);
              return;
            }
            setEventFilePreview(preview);
            const formdata = new FormData();
            formdata.append("image", file);
            setEventFileName(file.name);
            try {
              const response = await FileUpload(formdata);
              if (response && response.status === 200) {
                setUploadStatus(false);
                setEventFile(response.data.fileName);
                setEventId("");
                setError({ ...error, eventFile: "" });
              }
            } catch (error) {
              console.log(error);
            }
          };
        } else {
          if (file.size > 500485760) {
            setError({
              ...error,
              eventFile: "Event Video should be less than 500 MB",
            });
            setUploadStatus(false);
            return;
          }

          let video = document.createElement("video");
          video.src = preview;
          video.addEventListener("loadedmetadata", async () => {
            if (video.videoWidth / video.videoHeight < 1) {
              setError({
                ...error,
                eventFile:
                  "Event Video should have an Aspect Ratio of 1:1, 4:3, 16:9, 16:10",
              });
              setUploadStatus(false);
              return;
            }

            setEventFilePreview(preview);
            setGenerateThumb(true);

            const formdata = new FormData();
            formdata.append("image", file);

            setEventFileName(file.name);

            try {
              const response = await FileUpload(formdata);
              if (response && response.status === 200) {
                setUploadStatus(false);
                setEventFile(response.data.fileName);
                setEventId(response.data.eventId);
                setError({ ...error, eventFile: "" });
              }
            } catch (error) {
              console.log(error);
            }
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  // Ticket Type handling
  const handleTicketType = (text) => {
    if (text === "Limited") {
      setCheckedLimited(true);
      setCheckedUnLimited(false);
    } else if (text === "Unlimited") {
      setCheckedLimited(false);
      setCheckedUnLimited(true);
      setNoOfTickets("");
      setEarningsTicketSold("");
      setNoOfEBTickets("");
      setDiscount("");
      setEarningsEst("");
    }
    setTicketType(text);
    setError({ ...error, ticketType: "" });
  };

  // Estimated Total Earnings
  useEffect(() => {
    if (audience === "Corporate") {
      setEByesno("");
      setNoOfEBTickets("");
      setDiscount("");
      setTicketType("");
      setCheckedLimited(false);
      setCheckedUnLimited(false);
      setEarningsTicketSold("");
      setEarningsEst("");

      let array = [...corporateEmail];

      for (let i = 0; i < array.length; i++) {
        array[i].price = array[i].price ? array[i].price : ticketPrice;
      }
      setCorporateEmail(array);
    } else {
      setCorporateChecked("");
    }

    if (ticketPrice && noOfTickets) {
      if (noOfEBTickets && discount) {
        let EBearnings =
          parseInt(noOfEBTickets) *
          (parseInt(discount) / 100) *
          parseFloat(ticketPrice);

        let otherEarnings = parseInt(noOfTickets) * parseFloat(ticketPrice);

        let totalEarnings = parseFloat(
          parseFloat(otherEarnings) - parseFloat(EBearnings)
        ).toFixed(2);

        setEarningsEst(totalEarnings);
      } else {
        let totalEarnings = parseFloat(
          parseInt(noOfTickets) * parseFloat(ticketPrice)
        ).toFixed(2);

        setEarningsEst(totalEarnings);
      }
    }
  }, [audience, ticketPrice, noOfTickets, noOfEBTickets, discount]);

  const showCalcPrice = (numTic) => {
    if (ticketPrice && ticketType !== "Limited") {
      if (noOfEBTickets && discount) {
        let EBearnings =
          parseInt(noOfEBTickets > numTic ? numTic : noOfEBTickets) *
          (parseInt(discount) / 100) *
          parseFloat(ticketPrice);

        let otherEarnings = parseInt(numTic) * parseFloat(ticketPrice);

        let totalEarnings = parseFloat(
          parseFloat(otherEarnings) - parseFloat(EBearnings)
        ).toFixed(2);

        setEarningsEst(numTic ? totalEarnings : "");
      } else {
        let totalEarnings = parseFloat(
          numTic * parseFloat(ticketPrice)
        ).toFixed(2);

        setEarningsEst(numTic ? totalEarnings : "");
      }
    }
  };

  useEffect(() => {
    showCalcPrice(parseInt(earningsTicketSold));
  }, [earningsTicketSold, discount, noOfEBTickets]);

  useEffect(() => {
    if (
      earningsTicketPrice &&
      earningsTicketSold &&
      audience === "Corporate" &&
      corporateChecked === "specific"
    ) {
      let totalEarnings = parseFloat(
        parseInt(earningsTicketSold) * parseFloat(earningsTicketPrice)
      ).toFixed(2);

      setEarningsEst(
        earningsTicketPrice && earningsTicketSold ? totalEarnings : ""
      );
    }
  }, [earningsTicketPrice, earningsTicketSold]);

  // Earnings Calculator
  useEffect(() => {
    if (calcNoOfTickets && calcTicPrice) {
      let totalEarnings = parseFloat(
        parseInt(calcNoOfTickets) * parseFloat(calcTicPrice)
      ).toFixed(2);
      setCalcEstimate(totalEarnings);
    }
  }, [calcNoOfTickets, calcTicPrice]);

  // Check Company details
  useEffect(() => {
    if (audience === "Corporate") {
      setCheckActorStatus(false);
      CheckCompany()
        .then((response) => {
          setCheckActorStatus(true);
        })
        .catch((error) => {
          setError({ ...error, audience: error.response.data.message });
          setCheckActorStatus(false);
        });
    }
  }, [audience]);

  // Handling changes to be reflected on Preview
  const handlePreview = async () => {
    if (audience === "Corporate" && corporateChecked === "") {
      setError({
        ...error,
        corporateChecked: "Please select Corporate user type",
      });
      return;
    }

    if (audience === "Corporate" && corporateChecked === "specific") {
      setCorporateEmailError("");
      if (
        corporateEmail[0].price === "" ||
        corporateEmail[0].price === undefined ||
        corporateEmail[0].email === "" ||
        corporateEmail[0].email === undefined
      ) {
        setCorporateEmailError("Please enter email/price");
        return;
      } else {
        if (!Validation.email(corporateEmail[0].email)) {
          setCorporateEmailError("Please enter the email in a valid format");
          return;
        } else if (!Validation.emailLength(corporateEmail[0].email)) {
          setCorporateEmailError("Max 100 characters allowed");
          return;
        } else if (similarEmailCheck()) {
          setCorporateEmailError("Cannot use same email");
          return;
        } else if (parseFloat(corporateEmail[0].price) < 74.99) {
          setCorporateEmailError("Selling Price cannot be less than 100 Euros");
          return;
        } else {
          try {
            const response = await CheckCorporateEmail({
              email: corporateEmail[0].email,
            });

            if (response && response.status === 200) {
              console.log(response.data.message);
            }
          } catch (error) {
            setCorporateEmailError(error.response.data.message);
            Notify("danger", `Error: ${error.response.data.message}`);
            return;
          }
        }
      }
    }

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    let fields = [
      "audience",
      "audienceCheck",
      "eventCategory",
      "eventTitle",
      "ticketType",
      "ticketPrice",
      "noOfTickets",
      "EByesno",
      "noOfEBTickets",
      "discount",
      "EBSellingPrice",
      "timezone",
      "eventDate",
      "eventTime",
      "duration",
      "eventFile",
      "description",
    ];
    let isvalid = false;
    for (let i = 0; i < fields.length; i++) {
      isvalid = handleValidation(fields[i]);
      if (!isvalid) break;
    }
    if (!isvalid) return;
    let finalTime = [`${eventTime.getHours()}`, `${eventTime.getMinutes()}`];
    if (finalTime[0].length < 2) {
      finalTime[0] = "0" + finalTime[0];
    }
    if (finalTime[1] < 2) {
      finalTime[1] = finalTime[1] + "0";
    }
    let payload = {
      eventDuration: duration,
      setTimeZone: timezone,
      uniqueEventTimestamp:
        parseInt(
          +moment.tz(
            `${moment(eventDate).format("YYYY-MM-DD")} ${finalTime[0]}:${finalTime[1]
            }`,
            timezone
          )
        ) / 1000,
      eventDate: moment(eventDate).format("YYYY-MM-DD"),
      eventTime: `${finalTime[0]}:${finalTime[1]}`,
    };
    // API call to check event timings
    try {
      let response = await CheckCreateEvent(payload);
      if (response.status === 200) {
        setEventPreviewClicked(true);
      }
    } catch (error) {
      setError({
        checkCreateEvent: error.response.data.message,
      });
    }
  };

  const isFetched = (response) => {
    if (response.status === 200) {
      setSelectedTab(1);
      Store.store.dispatch({ type: "UPDATE_USERDATA", payload: true });
    }
  };

  // Submit data for creating Event
  const handleCreateEvent = async () => {
    setDisableCreate(true);

    let finalTime = [`${eventTime.getHours()}`, `${eventTime.getMinutes()}`];
    if (finalTime[0].length < 2) {
      finalTime[0] = "0" + finalTime[0];
    }
    if (finalTime[1] < 2) {
      finalTime[1] = finalTime[1] + "0";
    }

    let payload = {
      audienceType: audience,
      eventCategory: eventCategory,
      eventTitle: eventTitle,
      ticketType: audience !== "Corporate" ? ticketType : "",
      isUnlimited: ticketType === "Unlimited" ? true : false,
      price: corporateChecked === "specific" ? 0 : parseFloat(ticketPrice),
      availableTickets: noOfTickets ? parseInt(noOfTickets) : 0,
      earlyBirdTicket: noOfEBTickets ? parseInt(noOfEBTickets) : 0,
      discountPercentage: discount ? parseInt(discount) : 0,
      estimateEarning: earningsEst ? parseFloat(earningsEst) : 0,
      setTimeZone: timezone,
      eventDate: moment(eventDate).format("YYYY-MM-DD"),
      eventTime: `${finalTime[0]}:${finalTime[1]}`,
      eventImage: eventFile,
      description: description,
      uniqueEventTimestamp:
        parseInt(
          +moment.tz(
            `${moment(eventDate).format("YYYY-MM-DD")} ${finalTime[0]}:${finalTime[1]
            }`,
            timezone
          )
        ) / 1000,
      corporatePrice: corporateChecked === "all" ? [] : corporateEmail,
      videoStatus: eventFileType.split("/")[0] === "video" ? true : false,
      thumbnailImage:
        eventFileType.split("/")[0] === "video" ? videoThumbnail : "",
      eventDuration: duration,
      eventId: eventId,
      platform: streamPlatform,
      quality: streamPlatformQuality
    };

    try {
      const response = await CreateEventActor(payload);

      if (response && response.status === 200) {
        setEventPreviewClicked(false);

        setAudience("");
        setEventCategory("");
        setEventTitle("");
        setTicketType("");
        setCheckedLimited(false);
        setCheckedUnLimited(false);
        setTicketPrice("");
        setNoOfTickets("");
        setNoOfEBTickets("");
        setDiscount("");
        setEarningsTicketPrice("");
        setEarningsTicketSold("");
        setEarningsEst("");
        setTimeZone("");
        setEventDate("");
        setEventTime("");
        setEventFile("");
        setEventFileType("");
        setEventFileName("");
        setEventFilePreview("");
        setDescription("");
        setCorporateEmail([
          {
            email: "",
            price: "",
          },
        ]);
        setEByesno("");
        setError({});
        setDisableCreate(false);
        GetMyEvents(isFetched);

        Notify("success", "Event has been created successfully!!");
      }
    } catch (error) {
      Notify("danger", `Error: ${error.response.data.message}`);
      setDisableCreate(false);
    }
  };

  // Validator function
  const handleValidation = (type) => {

    if (type === "audience") {
      if (Validation.empty(audience)) {
        setError({ ...error, audience: "Audience Type is required" });
        return false;
      }
    } else if (type === "audienceCheck") {
      if (!checkActorStatus && audience === "Corporate") {
        setError({
          ...error,
          audience:
            "In order to host corporate events, you must complete your company details in My Account section.",
        });
        return false;
      }
    } else if (type === "eventCategory") {
      if (Validation.empty(eventCategory)) {
        setError({ ...error, eventCategory: "Event Category is required" });
        return false;
      }
    } else if (type === "eventTitle") {

      if (Validation.empty(eventTitle)) {
        setError({ ...error, eventTitle: "Event Title is required" });
        return false;
      }
    } else if (type === "ticketType") {

      if (audience === "Public") {
        if (Validation.empty(ticketType)) {
          setError({ ...error, ticketType: "Ticket Type is required" });
          return false;
        }
      }
    } else if (type === "ticketPrice" && corporateChecked !== "specific") {

      if (Validation.empty(ticketPrice)) {
        setError({ ...error, ticketPrice: "Ticket Price is required" });
        return false;
      } else if (audience === "Public" && parseFloat(ticketPrice) < 2.61) {
        setError({
          ...error,
          ticketPrice: "Selling Price cannot be less than 5 Euros",
        });
        return false;
      } else if (audience === "Corporate" && parseFloat(ticketPrice) < 74.99) {
        setError({
          ...error,
          ticketPrice: "Selling Price cannot be less than 100 Euros",
        });
        return false;
      }
    } else if (type === "EByesno" && audience === "Public") {

      if (Validation.empty(EByesno)) {
        setError({ ...error, EByesno: "Please select an option" });
        return false;
      }
    } else if (type === "timezone") {

      if (Validation.empty(timezone)) {
        setError({ ...error, timezone: "TimeZone is required" });
        return false;
      }
    } else if (type === "eventDate") {

      if (Validation.empty(eventDate ? eventDate?.toISOString() : "")) {
        setError({ ...error, eventDate: "Event Date is required" });
        return false;
      }
    } else if (type === "eventTime") {

      if (Validation.empty(eventTime ? eventTime?.toISOString() : "")) {
        setError({ ...error, eventTime: "Event Time is required" });
        return false;
      }
    } else if (type === "duration") {

      if (Validation.empty(duration)) {
        setError({ ...error, duration: "Duration is required" });
        return false;
      }
    } else if (type === "eventFile") {

      if (Validation.empty(eventFile)) {
        setError({ ...error, eventFile: "Event Image/ Video is required" });
        return false;
      }
    } else if (type === "description") {

      if (Validation.empty(description)) {
        setError({ ...error, description: "Description is required" });
        return false;
      }
    } else if (ticketType === "Limited") {
      if (audience === "Public") {
        if (type === "noOfTickets") {
          if (Validation.empty(noOfTickets)) {
            setError({
              ...error,
              noOfTickets: "Number of Tickets is required",
            });
            return false;
          } else if (parseInt(noOfTickets) < 100) {
            setError({
              ...error,
              noOfTickets: "Number of Tickets cannot be less than 100",
            });
            return false;
          }
        } else if (type === "noOfEBTickets" && EByesno === "yes") {
          if (Validation.empty(noOfEBTickets)) {
            setError({
              ...error,
              noOfEBTickets: "Number of Early Bird Tickets is required",
            });
            return false;
          } else if (parseInt(noOfTickets) / 2 < parseInt(noOfEBTickets)) {
            setError({
              ...error,
              noOfEBTickets:
                "Early bird tickets should be less than half of Total Available Tickets",
            });
            return false;
          }
        } else if (type === "discount" && EByesno === "yes") {
          if (Validation.empty(discount)) {
            setError({ ...error, discount: "Discount is required" });
            return false;
          }
        } else if (type === "EBSellingPrice" && EByesno === "yes") {
          if (EBSellingPrice < 5) {
            setError({
              ...error,
              EBSellingPrice: "Selling Price cannot be less than 5 Euros",
            });
            return false;
          }
        }
      } else if (audience === "Corporate") {
        if (type === "noOfTickets") {
          if (Validation.empty(noOfTickets)) {
            setError({
              ...error,
              noOfTickets: "Number of Tickets is required",
            });
            return false;
          } else if (parseInt(noOfTickets) < 100) {
            setError({
              ...error,
              noOfTickets: "Number of Tickets cannot be less than 100",
            });
            return false;
          }
        }
      }
    }
    return true;
  };

  const handleUnixDate = (e, type) => {
    if (type === "date") {
      setEventDate(e);
      if (eventTime) {
        var a = e;
        var b = new Date(unixDate);
        b.setFullYear(a.getFullYear(), a.getMonth(), a.getDate());
        setUnixDate(+b);
      } else {
        setUnixDate(+e);
      }
    } else if (type === "time") {
      setEventTime(e);
      if (eventDate) {
        var b = new Date(unixDate);
        b.setHours(e.getHours(), e.getMinutes(), "00", "00");
        setUnixDate(+b);
      } else {
        setUnixDate(
          +new Date().setHours(e.getHours(), e.getMinutes(), "00", "00")
        );
      }
    }
  };

  const handleCorporateChecked = (text) => {
    setCorporateChecked(text);
    if (text === "all") {
      setCorporateEmail([
        {
          email: "",
          price: "",
        },
      ]);
      setCorporateEmailError("");
    }
  };

  const handleEBChecked = (text) => {
    setEByesno(text);
  };

  useEffect(() => {
    if (EByesno === "no") {
      setNoOfEBTickets("");
      setDiscount("");
    }
  }, [EByesno]);

  const handleVideoMute = () => {
    setVideoMute(!videoMute);
  };

  const handleCorpEmailCheck = async (email) => {
    let payload = {
      email: email,
    };
    try {
      const response = await CheckCorporateEmail(payload);

      if (response && response.status === 200) {
        console.log(response.data.message);
      }
    } catch (error) {
      setCorporateEmailError(error.response.data.message);
      Notify("danger", `Error: ${error.response.data.message}`);
    }
  };

  return (
    <div id="app" className="page-create-event">
      <section className="create-event-section">
        <div className="flex-container ">
          <div className="breadcrumbs">
            {eventPreviewClicked ? (
              <ul>
                <li>
                  <a
                    className="cursorPointer"
                    onClick={() => setSelectedTab(1)}
                  >
                    Home
                  </a>{" "}
                  <span>&#62;</span>{" "}
                </li>
                <li>
                  <a
                    className="cursorPointer"
                    onClick={() => {
                      setEventPreviewClicked(false);
                    }}
                  >
                    Create Event
                  </a>{" "}
                  <span>&#62;</span>{" "}
                </li>
                <li>Event Preview</li>
              </ul>
            ) : (
              <ul>
                <li>
                  <a
                    className="cursorPointer"
                    onClick={() => setSelectedTab(1)}
                  >
                    Home
                  </a>{" "}
                  <span>&#62;</span>{" "}
                </li>
                <li>Create Event</li>
              </ul>
            )}
          </div>
          <div className="create-event">
            <div className="top-heading">
              <h1>Create an Event</h1>
            </div>
            <div
              style={{
                width: "1190px",
                left: "245px",
                top: "284px",
                background: "rgba(134, 134, 134, 0.1)",
                borderRadius: "5px",
              }}
            >
              <Progress
                stepper={stepper}
                streamType={streamType}
                setStepper={setStepper}
              />
              {stepper == 1 ? (
                <SelectEvent
                  setStreamType={setStreamType}
                  setStepper={setStepper}
                />
              ) : streamType == "rtmp" && stepper == 2 ? (
                <div>
                  {" "}
                  <SelectQuality
                    setStreamQuality={setShowStreamQuality}
                    setStepper={setStepper}
                    setShowCreateForm={setShowCreateForm}

                  />{" "}
                </div>
              ) : (
                <div></div>
              )}

              {(streamType == "easy" && stepper == 2) ||
                (streamType == "rtmp" && stepper == 3) ? (
                <div className="form">
                  <div>
                    <h1 className="streamh1">Lorem ipsum dollar site amet</h1>
                    <h5 className="streamboxh5">
                      Lorem Ipsum is simply dummy text of the sdsspthe
                      sdssprintingypesetting industry. Lorem Ipsum has been.
                    </h5>
                  </div>
                  <div className="formmain">
                    <form className="form-event">
                      <div className="field-wrap">
                        <label>Audience Type</label>
                        <select
                          name=""
                          id=""
                          className="chng-arrow"
                          value={audience}
                          onChange={(e) => setAudience(e.target.value)}
                          disabled={eventPreviewClicked}
                          onFocus={(e) => setError({ ...error, audience: "" })}
                          onBlur={(e) => handleValidation("audience")}
                        >
                          <option value="">Select Audience Type*</option>
                          <option value="Public">Public</option>
                          <option value="Corporate">Corporate</option>
                        </select>
                        {error && (
                          <span style={{ color: "red" }}>
                            {error?.audience}
                          </span>
                        )}
                        {audience === "Corporate" && (
                          <>
                            <div className="flex-row">
                              <div className="ticket corpTicket">
                                <label className="pure-checkbox">
                                  <input
                                    type="radio"
                                    value="all"
                                    name="coporateType"
                                    onChange={(e) => {
                                      handleCorporateChecked(e.target.value);
                                      setError({
                                        ...error,
                                        corporateChecked: "",
                                      });
                                    }}
                                    disabled={eventPreviewClicked}
                                  />
                                  <span
                                    className="checkmark"
                                    style={{ top: "5px" }}
                                  ></span>
                                  All Corporate Users
                                </label>
                              </div>
                              <div className="ticket corpTicket">
                                <label className="pure-checkbox">
                                  <input
                                    type="radio"
                                    value="specific"
                                    name="coporateType"
                                    onChange={(e) => {
                                      handleCorporateChecked(e.target.value);
                                      setError({
                                        ...error,
                                        corporateChecked: "",
                                      });
                                    }}
                                    disabled={eventPreviewClicked}
                                  />
                                  <span
                                    className="checkmark"
                                    style={{ top: "5px" }}
                                  ></span>
                                  Specific Users
                                </label>
                              </div>
                            </div>
                            {error && (
                              <span style={{ color: "red" }}>
                                {error?.corporateChecked}
                              </span>
                            )}
                          </>
                        )}
                      </div>
                      {audience === "Corporate" &&
                        corporateChecked === "specific" &&
                        corporateEmail.map((item, index) => (
                          <div key={index}>
                            <div className="flex-row-3 gutter-space">
                              <div className="col-flex-3 width-email">
                                <div className="field-wrap">
                                  {index < 1 && (
                                    <label>
                                      Enter Corporate User Email Address
                                    </label>
                                  )}
                                  <input
                                    type="email"
                                    id="birthday"
                                    name="email"
                                    placeholder="Email Address"
                                    value={item.email}
                                    onChange={(e) =>
                                      handleCorporateEmailText(
                                        e.target.value,
                                        index,
                                        "email"
                                      )
                                    }
                                    onFocus={(e) => setCorporateEmailError("")}
                                    onBlur={(e) => {
                                      if (e.target.value)
                                        handleCorpEmailCheck(e.target.value);
                                    }}
                                    disabled={eventPreviewClicked || index > 0}
                                  />
                                </div>
                              </div>
                              <div className="col-flex-3 width-sell-price">
                                <div className="field-wrap">
                                  {index < 1 && (
                                    <label className="empty"></label>
                                  )}
                                  <input
                                    type="text"
                                    id="appt"
                                    name="price"
                                    placeholder="Earnings per ticket"
                                    value={item.price}
                                    onChange={(e) =>
                                      handleCorporateEmailText(
                                        e.target.value.replace(
                                          /[^0-9|^.]/g,
                                          ""
                                        ),
                                        index,
                                        "price"
                                      )
                                    }
                                    onFocus={(e) => setCorporateEmailError("")}
                                    onBlur={(e) => {
                                      if (e.target.value) {
                                        handleCorporateEmailText(
                                          e.target.value + ".00",
                                          index,
                                          "price"
                                        );
                                      }
                                    }}
                                    disabled={eventPreviewClicked || index > 0}
                                  />
                                </div>
                              </div>
                              <div className="col-flex-3 width-price">
                                <div className="field-wrap pirice-add">
                                  {index < 1 && (
                                    <label className="empty"></label>
                                  )}
                                  <input
                                    type="text"
                                    name="selling-price"
                                    placeholder="Selling Price In Euro"
                                    value={item.sellingPrice}
                                    disabled={eventPreviewClicked || index > 0}
                                    onChange={(e) =>
                                      handleCorporateEmailText(
                                        e.target.value.replace(
                                          /[^0-9|^.]/g,
                                          ""
                                        ),
                                        index,
                                        "sellingPrice"
                                      )
                                    }
                                    onFocus={(e) => setCorporateEmailError("")}
                                    onBlur={(e) => {
                                      if (e.target.value) {
                                        handleCorporateEmailText(
                                          e.target.value + ".00",
                                          index,
                                          "sellingPrice"
                                        );
                                      }
                                    }}
                                  />
                                  {!eventPreviewClicked &&
                                    (index < 1 ? (
                                      <div className="icon-plus-wrapper">
                                        <a
                                          onClick={() =>
                                            handleAddCorporateEmail()
                                          }
                                        >
                                          <img src={IconAddRound} alt="" />
                                        </a>
                                      </div>
                                    ) : (
                                      <div className="icon-plus-wrapper minus-price">
                                        <a
                                          onClick={() =>
                                            handleRemoveCorporateEmail(index)
                                          }
                                        >
                                          <img src={MinusPNG} alt="" />
                                        </a>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>

                            {index < 1 && corporateEmailError && (
                              <div style={{ textAlign: "center" }}>
                                <span style={{ color: "red" }}>
                                  {corporateEmailError}
                                </span>
                              </div>
                            )}
                          </div>
                        ))}
                      <div className="field-wrap ">
                        <label>Event Category</label>
                        <select
                          name=""
                          id=""
                          className="chng-arrow"
                          value={eventCategory}
                          onChange={(e) => setEventCategory(e.target.value)}
                          disabled={eventPreviewClicked}
                          onFocus={(e) =>
                            setError({ ...error, eventCategory: "" })
                          }
                          onBlur={(e) => handleValidation("eventCategory")}
                        >
                          <option value="">Select Event Category</option>

                          {categoryList.map(
                            (cat, index) =>
                              !cat.actorCategory && (
                                <option value={cat.categoryName} key={index}>
                                  {cat.categoryName}
                                </option>
                              )
                          )}
                        </select>
                        {error && (
                          <span style={{ color: "red" }}>
                            {error?.eventCategory}
                          </span>
                        )}
                      </div>
                      <div className="field-wrap ">
                        <label>Event Title</label>
                        <input
                          type="text"
                          name=""
                          required=""
                          placeholder="Title*"
                          value={eventTitle}
                          onChange={(e) => setEventTitle(e.target.value)}
                          disabled={eventPreviewClicked}
                          onFocus={(e) =>
                            setError({ ...error, eventTitle: "" })
                          }
                          onBlur={(e) => handleValidation("eventTitle")}
                          maxLength="50"
                        />
                        {error && (
                          <span style={{ color: "red" }}>
                            {error?.eventTitle}
                          </span>
                        )}
                      </div>
                      {audience === "Public" && (
                        <div className="flex-row gutter-space">
                          <div className="flex-col-2">
                            <div className="field-wrap checkbox">
                              <label>Ticket Type</label>
                              <div className="ticket">
                                <label className="pure-checkbox">
                                  <input
                                    type="checkbox"
                                    value="Limited"
                                    name="pubTicType"
                                    checked={checkedLimited}
                                    onChange={(e) =>
                                      handleTicketType(e.target.value)
                                    }
                                    disabled={eventPreviewClicked}
                                  />
                                  <span className="checkmark"></span>Limited
                                  Ticket
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="flex-col-2">
                            <div className="field-wrap checkbox">
                              <label className="empty"></label>
                              <div
                                className="ticket"
                                style={{ marginLeft: "auto" }}
                              >
                                <label className="pure-checkbox">
                                  <input
                                    type="checkbox"
                                    value="Unlimited"
                                    name="pubTicType"
                                    checked={checkedUnLimited}
                                    onChange={(e) =>
                                      handleTicketType(e.target.value)
                                    }
                                    disabled={eventPreviewClicked}
                                  />
                                  <span className="checkmark"></span>Unlimited
                                  Ticket
                                </label>
                              </div>
                            </div>
                          </div>
                          {error && (
                            <span style={{ color: "red" }}>
                              {error?.ticketType}
                            </span>
                          )}
                        </div>
                      )}
                      {ticketType === "Limited" && (
                        <div className="field-wrap">
                          <label>Number of Available Ticket</label>
                          <input
                            type="text"
                            name=""
                            required=""
                            placeholder="Number of available tickets*"
                            value={noOfTickets}
                            onChange={(e) => {
                              setNoOfTickets(
                                e.target.value.replace(/[^0-9]/g, "")
                              );
                              setEarningsTicketSold(
                                e.target.value.replace(/[^0-9]/g, "")
                              );
                            }}
                            disabled={eventPreviewClicked}
                            onFocus={(e) =>
                              setError({ ...error, noOfTickets: "" })
                            }
                            onBlur={(e) => handleValidation("noOfTickets")}
                          />
                          {error && (
                            <span style={{ color: "red" }}>
                              {error?.noOfTickets}
                            </span>
                          )}
                        </div>
                      )}
                      {corporateChecked !== "specific" && (
                        <div className="field-wrap">
                          <div className="flex-row gutter-space">
                            <div className="flex-col-2">
                              <label>
                                How much would you like to earn per ticket?
                              </label>
                              <input
                                type="text"
                                name=""
                                required=""
                                placeholder="Earnings per ticket"
                                value={ticketPrice}
                                onChange={(e) => {
                                  handlePriceChange(
                                    e.target.value.replace(/[^0-9|^.]/g, ""),
                                    "price"
                                  );
                                }}
                                disabled={eventPreviewClicked}
                                onFocus={(e) =>
                                  setError({
                                    ...error,
                                    ticketPrice: "",
                                    EBSellingPrice: "",
                                  })
                                }
                                onBlur={(e) => {
                                  handleValidation("ticketPrice");
                                  if (e.target.value) {
                                    handlePriceChange(
                                      e.target.value + ".00",
                                      "price"
                                    );
                                  }
                                }}
                              />
                            </div>
                            <div className="flex-col-2">
                              <label>
                                Viewer Pays (you can also type here)
                              </label>
                              <input
                                type="text"
                                name=""
                                required=""
                                placeholder="Selling Price in Euro"
                                value={normalSellingPrice}
                                onChange={(e) => {
                                  handlePriceChange(
                                    e.target.value.replace(/[^0-9|^.]/g, ""),
                                    "sellingPrice"
                                  );
                                }}
                                disabled={eventPreviewClicked}
                                onFocus={(e) =>
                                  setError({
                                    ...error,
                                    ticketPrice: "",
                                    EBSellingPrice: "",
                                  })
                                }
                                onBlur={(e) => {
                                  handleValidation("ticketPrice");
                                  if (e.target.value) {
                                    handlePriceChange(
                                      e.target.value + ".00",
                                      "sellingPrice"
                                    );
                                  }
                                }}
                              />
                            </div>
                          </div>
                          {error && (
                            <span style={{ color: "red" }}>
                              {error?.ticketPrice}
                            </span>
                          )}
                        </div>
                      )}
                      {audience === "Public" && (
                        <>
                          <div className="field-wrap">
                            Would you like to sell early bird tickets at a
                            discount?
                            <div className="flex-row">
                              <div className="ticket corpTicket">
                                <label className="pure-checkbox">
                                  <input
                                    type="radio"
                                    value="yes"
                                    name="EByesno"
                                    onChange={(e) => {
                                      handleEBChecked(e.target.value);
                                      setError({
                                        ...error,
                                        EByesno: "",
                                      });
                                    }}
                                    disabled={eventPreviewClicked}
                                  />
                                  <span
                                    className="checkmark"
                                    style={{ top: "5px" }}
                                  ></span>
                                  Yes
                                </label>
                              </div>

                              <div className="ticket corpTicket">
                                <label className="pure-checkbox">
                                  <input
                                    type="radio"
                                    value="no"
                                    name="EByesno"
                                    onChange={(e) => {
                                      handleEBChecked(e.target.value);
                                      setError({
                                        ...error,
                                        EByesno: "",
                                      });
                                    }}
                                    disabled={eventPreviewClicked}
                                    checked={EByesno === "no"}
                                  />
                                  <span
                                    className="checkmark"
                                    style={{ top: "5px" }}
                                  ></span>
                                  No
                                </label>
                              </div>
                            </div>
                            {error && (
                              <span style={{ color: "red" }}>
                                {error?.EByesno}
                              </span>
                            )}
                          </div>

                          {EByesno === "yes" && (
                            <div className="field-wrap">
                              <label>Early Bird Tickets</label>
                              <div className="flex-row gutter-space">
                                <div className="flex-col-2">
                                  <input
                                    type="text"
                                    name=""
                                    required=""
                                    placeholder="Number of available tickets*"
                                    value={noOfEBTickets}
                                    onChange={(e) =>
                                      setNoOfEBTickets(
                                        e.target.value.replace(/[^0-9]/g, "")
                                      )
                                    }
                                    disabled={eventPreviewClicked}
                                    onFocus={(e) =>
                                      setError({ ...error, noOfEBTickets: "" })
                                    }
                                    onBlur={(e) =>
                                      handleValidation("noOfEBTickets")
                                    }
                                  />
                                </div>
                                <div className="flex-col-2">
                                  <input
                                    type="text"
                                    name=""
                                    required=""
                                    placeholder="Earnings per ticket"
                                    value={pricePerTicket}
                                    disabled={true}
                                  />
                                </div>
                                {error && (
                                  <span style={{ color: "red" }}>
                                    {error?.noOfEBTickets}
                                  </span>
                                )}
                              </div>
                              <div className="flex-row gutter-space">
                                <div className="flex-col-2">
                                  <input
                                    type="text"
                                    name=""
                                    required=""
                                    placeholder="Discount ( in percent )"
                                    value={discount}
                                    maxLength="2"
                                    onChange={(e) =>
                                      setDiscount(
                                        e.target.value.replace(/[^0-9]/g, "")
                                      )
                                    }
                                    disabled={eventPreviewClicked}
                                    onFocus={(e) =>
                                      setError({
                                        ...error,
                                        discount: "",
                                        EBSellingPrice: "",
                                      })
                                    }
                                    onBlur={(e) => handleValidation("discount")}
                                  />
                                  {error && (
                                    <span style={{ color: "red" }}>
                                      {error?.discount}
                                    </span>
                                  )}
                                </div>
                                <div className="flex-col-2">
                                  <input
                                    type="text"
                                    name=""
                                    required=""
                                    placeholder="Viewer Pays"
                                    value={EBSellingPrice}
                                    disabled={true}
                                  />
                                  {error && (
                                    <span style={{ color: "red" }}>
                                      {error?.EBSellingPrice}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      <div className="field-con">
                        <div className="con-label">
                          <label>
                            {corporateChecked === "specific"
                              ? "Estimated Earnings (if all tickets are sold)"
                              : "Calculate Your Potential Earnings"}
                          </label>
                        </div>
                        {corporateChecked === "specific" ? (
                          <div className="filed-con-inner">
                            <div className="field-wrap inner-labels">
                              <input
                                type="text"
                                name=""
                                required=""
                                placeholder="Estimated Earnings in €"
                                value={parseFloat(addSpecificPrice).toFixed(2)}
                                disabled={true}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="filed-con-inner">
                            <div className="flex-row gutter-space">
                              <div className="flex-col-2">
                                <div className="field-wrap inner-labels">
                                  <label>Ticket Price</label>
                                  <input
                                    type="text"
                                    name=""
                                    required=""
                                    placeholder="Enter ticket price €"
                                    value={earningsTicketPrice}
                                    onChange={(e) =>
                                      setEarningsTicketPrice(
                                        e.target.value.replace(/[^0-9|^.]/g, "")
                                      )
                                    }
                                    disabled={
                                      corporateChecked === "specific"
                                        ? false
                                        : true
                                    }
                                  />
                                </div>
                              </div>
                              <div className="flex-col-2">
                                <div className="field-wrap inner-labels symbol-x">
                                  <label>Ticket Sold</label>
                                  <input
                                    type="text"
                                    name=""
                                    required=""
                                    placeholder="No. of ticket sold"
                                    value={earningsTicketSold}
                                    onChange={(e) =>
                                      setEarningsTicketSold(
                                        e.target.value.replace(/[^0-9|^]/g, "")
                                      )
                                    }
                                    disabled={
                                      audience === "Public" &&
                                      ticketType === "Limited"
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="field-wrap inner-labels">
                              <label>Estimated Earning</label>
                              <input
                                type="text"
                                name=""
                                required=""
                                placeholder="Estimated Earnings in €"
                                value={earningsEst}
                                disabled={true}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="field-wrap">
                        <label>Select Timezone</label>
                        <select
                          className="chng-arrow"
                          value={timezone}
                          onChange={(e) => setTimeZone(e.target.value)}
                          disabled={eventPreviewClicked}
                          onFocus={(e) =>
                            setError({
                              ...error,
                              timezone: "",
                              checkCreateEvent: "",
                            })
                          }
                          onBlur={(e) => handleValidation("timezone")}
                        >
                          <option value="">Select Timezone</option>
                          {tzList?.map((item, index) => (
                            <option value={item.value} key={index}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                        {error && (
                          <span style={{ color: "red" }}>
                            {error?.timezone}
                          </span>
                        )}
                      </div>
                      <div className="flex-row gutter-space">
                        <div className="flex-col-2">
                          <div className="field-wrap time">
                            <label>Event Date and Time</label>
                            <DatePicker
                              selected={eventDate}
                              placeholderText="Select Date"
                              minDate={
                                // new Date(new Date().setDate(new Date().getDate() + 2))
                                new Date(
                                  new Date().setDate(new Date().getDate() + 1)
                                )
                              }
                              onChange={(date) => {
                                handleUnixDate(date, "date");
                              }}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              className="datepicker-react-input"
                              disabled={eventPreviewClicked}
                              onFocus={(e) =>
                                setError({
                                  ...error,
                                  eventDate: "",
                                  checkCreateEvent: "",
                                })
                              }
                              onBlur={(e) => handleValidation("eventDate")}
                            />
                            {error && (
                              <span style={{ color: "red" }}>
                                {error?.eventDate}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="flex-col-2">
                          <div className="field-wrap time">
                            <label className="empty"></label>
                            <DatePicker
                              label="date"
                              selected={eventTime}
                              placeholderText="Select Time"
                              onChange={(date) => handleUnixDate(date, "time")}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              // timeCaption="Select Time"
                              dateFormat="h:mm aa"
                              className="datepicker-react-input"
                              disabled={eventPreviewClicked}
                              onFocus={(e) =>
                                setError({
                                  ...error,
                                  eventTime: "",
                                  checkCreateEvent: "",
                                })
                              }
                              onBlur={(e) => handleValidation("eventTime")}
                            />
                            {error && (
                              <span style={{ color: "red" }}>
                                {error?.eventTime}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      {error && (
                        <div
                          className="flex-wrap"
                          style={{ textAlign: "center" }}
                        >
                          <span style={{ color: "red" }}>
                            {error?.checkCreateEvent}
                          </span>
                        </div>
                      )}
                      <div className="field-wrap">
                        <label>Select Duration</label>
                        <select
                          className="chng-arrow"
                          value={duration}
                          onChange={(e) => setDuration(e.target.value)}
                          disabled={eventPreviewClicked}
                          onFocus={(e) =>
                            setError({
                              ...error,
                              duration: "",
                              checkCreateEvent: "",
                            })
                          }
                          onBlur={(e) => handleValidation("duration")}
                        >
                          <option value="">Select Duration</option>
                          <option value="30">30 minutes</option>
                          <option value="60">1 hour</option>
                          <option value="90">1 hour 30 minutes</option>
                          <option value="120">2 hours</option>
                          <option value="150">2 hours 30 minutes</option>
                          <option value="180">3 hours</option>
                          <option value="210">3 hours 30 minutes</option>
                          <option value="240">4 hours</option>
                        </select>
                        {error && (
                          <span style={{ color: "red" }}>
                            {error?.duration}
                          </span>
                        )}
                      </div>
                      <div className="field-wrap file-section">
                        <label>Select Event Image/Video</label>
                        {!eventPreviewClicked ? (
                          <>
                            <div className="field-upload">
                              <label className="label-upload">
                                Upload a Picture/Video{" "}
                              </label>
                              <input
                                type="file"
                                accept="image/*, video/*"
                                onChange={(e) => handleImage(e.target.files[0])}
                              />
                              <p>
                                {eventFileName
                                  ? eventFileName
                                  : "No files selected"}
                              </p>
                            </div>
                            {uploadStatus && (
                              <div className="custom-loader">
                                <Logo
                                  type="TailSpin"
                                  color="red"
                                  secondaryColor="red"
                                  height={75}
                                  width={100}
                                />
                              </div>
                            )}
                          </>
                        ) : eventFileType?.split("/")[0] === "image" ? (
                          <img src={eventFilePreview} alt="User" />
                        ) : eventFileType?.split("/")[0] === "video" ? (
                          <div style={{ position: "relative" }}>
                            <div
                              className="cursorPointer video-mute-div"
                              onClick={() => handleVideoMute()}
                            >
                              {videoMute ? (
                                <img src={iconMute} />
                              ) : (
                                <img src={iconUnMute} />
                              )}
                            </div>
                            <video
                              width="100%"
                              height="480"
                              autoPlay={true}
                              loop={true}
                              muted={videoMute}
                            >
                              <source
                                src={eventFilePreview}
                                type={eventFileType}
                              />
                            </video>
                          </div>
                        ) : (
                          <div className="field-upload">
                            <p> No files selected. </p>
                          </div>
                        )}
                        {error && (
                          <span style={{ color: "red" }}>
                            {error?.eventFile}
                          </span>
                        )}
                      </div>

                      <div className="field-wrap">
                        <label>Event’s Description</label>
                        <textarea
                          id="review"
                          name="review"
                          rows="7"
                          cols="50"
                          placeholder="Write event description here..."
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          disabled={eventPreviewClicked}
                          onFocus={(e) =>
                            setError({ ...error, description: "" })
                          }
                          onBlur={(e) => handleValidation("description")}
                        ></textarea>
                        {error && (
                          <span style={{ color: "red" }}>
                            {error?.description}
                          </span>
                        )}
                      </div>
                      <div className="btn-wrap text-center1" style={{ paddingBottom: '50px', textAlign: 'center' }}>
                        <button
                          type="button"
                          className="primary-button"
                          onClick={(e) => {
                            eventPreviewClicked
                              ? handleCreateEvent()
                              : handlePreview();
                          }}
                          disabled={disableCreate}
                        >
                          {eventPreviewClicked
                            ? "Create Event"
                            : "View Preview"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </section>

      {eventFileType?.split("/")[0] === "video" && generateThumb && (
        <div>
          <VideoThumbnail
            videoUrl={eventFilePreview}
            thumbnailHandler={async (thumbnail) => {
              var arr = thumbnail.split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
              while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
              }
              var file = new File([u8arr], "thumbnail.jpg", { type: mime });
              const formdata = new FormData();
              formdata.append("image", file);
              try {
                const response = await FileUpload(formdata);
                setVideoThumbnail(response.data.fileName);
              } catch (error) {
                console.log(error);
              }
            }}
            snapshotAtTime={10}
            renderThumbnail={false}
          />
        </div>
      )}
    </div>
  );
}

export default connect(null, {
  GetMyEvents,
})(CreateEvent);