import React, { useState, useRef } from "react";
import HD from "../../../assets/img/HD.png";
import FHD from "../../../assets/img/FHD.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SelectQuality({ setStreamQuality, setStepper, setShowCreateForm }) {

  const [state1, setState1] = useState("")
  const bottomRef = useRef(null);

  const handleContinueButton = () => {
    if (state1 !== "") {
      setStreamQuality(state1);
      setShowCreateForm(state1)
      setStepper(3);
    } else {
      toast.error("Please select atleast one option");
    }
  };

  return (
    <div ref={bottomRef}>
      <ToastContainer />
      <div style={{ marginLeft: "150px" }}>
        <h1 className="streamh1">Select Quality Of Stream</h1>
        <h5 className="streamboxh5">
          Lorem Ipsum is simply dummy text of the sdsspthe
          sdssprintingypesetting industry. Lorem Ipsum has been.
        </h5>
      </div>
      <div className="stream">
        <box
          className={state1 === "1080p" ? "streambox streamboxActive" : "streambox"} id="1080p" onClick={() => setState1("1080p")}>

          <div onClick={() => bottomRef.current?.scrollIntoView({ behavior: 'smooth', inline: "nearest" })} className="streamboxContent" >
            <img src={HD} />
            <h4 className="streamboxContenth4">HD upto 1080p </h4>
            <ul>
              <li className="streamboxContentli">Lorem Ipsum dollar site</li>
              <li className="streamboxContentli">
                Lorem Ipsum dollar amet is dalast
              </li>
              <li className="streamboxContentli">Lorem Ipsum dollar site</li>
              <li className="streamboxContentli">
                Lorem Ipsum dollar amet is dalast
              </li>
            </ul>
          </div>
        </box>
        <box className={state1 === "4k" ? "streambox streamboxActive" : "streambox"} id="4k" onClick={() => setState1("4k")}>
          <div onClick={() => bottomRef.current?.scrollIntoView({ behavior: 'smooth', inline: "nearest" })} className="streamboxContent">
            <img src={FHD} />
            <h4 className="streamboxContenth4">FHD upto 4k</h4>
            <ul>
              <li className="streamboxContentli">Lorem Ipsum dollar site</li>
              <li className="streamboxContentli">
                Lorem Ipsum dollar amet is dalast
              </li>
              <li className="streamboxContentli">Lorem Ipsum dollar site</li>
              <li className="streamboxContentli">Lorem Ipsum dollar site</li>
              <li className="streamboxContentli">
                Lorem Ipsum dollar amet is dalast
              </li>
            </ul>
          </div>
        </box>
      </div>
      <div className="btn-con">
        <button
          type="button"
          className="primary-buttonn"
          onClick={() => handleContinueButton()}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default SelectQuality;